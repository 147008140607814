/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;

}

.clients img {
  max-width: 50%;
  /* width: 100%; */
  /* height: 20%; */
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 35px 0;
}

.clients img:hover {
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

.vl {
  border-left: 1px solid #000; /* Adjust the color and width as needed */
  height: 100%; /* Adjust this value to match the height of your columns */
}