/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 100vh;
    background: url("../../../public/assets/img/hero-bg.jpg") top left;
    background-size: cover;
    position: relative;
  }
  
  #hero:before {
    content: "";
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .container {
    position: relative;
  }
  
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #222222;
    font-family: "Poppins", sans-serif;
  }
  
  #hero h1 span {
    color: #52a22a;
  }
  
  #hero h2 {
    color: #555555;
    margin: 5px 0 30px 0;
    font-size: 24px;
    font-weight: 400;
  }
  
  #hero .btn-get-started {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #52a22a;
  }
  
  #hero .btn-get-started:hover {
    background: #247cf0;
  }
  
  #hero .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: #222222;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  
  #hero .btn-watch-video i {
    color: #52a22a;
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
  }
  
  #hero .btn-watch-video:hover {
    color: #52a22a;
  }
  
  #hero .btn-watch-video:hover i {
    color: #3b8af2;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 768px) {
    #hero {
      height: 100vh;
    }
  
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  
    #hero .btn-get-started,
    #hero .btn-watch-video {
      font-size: 13px;
    }
  }
  
  @media (max-height: 500px) {
    #hero {
      height: 120vh;
    }
  }
  

  /* carousal */

  
.carousel {
  position: relative
}

.carousel.pointer-event {
  touch-action: pan-y
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden
}

.carousel-inner:after {
  display: block;
  clear: both;
  content: ""
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
  .carousel-item {
    transition: none
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%)
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  /* transform: none; */
  transition-timing-function: ease-out;
  transition-duration: 3s;
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s
}

@media(prefers-reduced-motion:reduce) {

  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease
}

@media(prefers-reduced-motion:reduce) {

  .carousel-control-next,
  .carousel-control-prev {
    transition: none
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9
}

.carousel-control-prev {
  left: 0
}

.carousel-control-next {
  right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%
}

/*!rtl:options:{
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none
}

.carousel-indicators [data-mdb-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease
}

@media(prefers-reduced-motion:reduce) {
  .carousel-indicators [data-mdb-target] {
    transition: none
  }
}

.carousel-indicators .active {
  opacity: 1
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-mdb-target] {
  background-color: #000
}

.carousel-dark .carousel-caption {
  color: #000
}


.carousel-control-prev-icon:after {
  content: ""
}

.carousel-control-next-icon:after,
.carousel-control-prev-icon:after {
  --mdb-carousel-control-icon-font-size: 1.7rem;
  --mdb-carousel-control-icon-font-weight: 700;
  font-weight: var(--mdb-carousel-control-icon-font-weight);
  font-size: var(--mdb-carousel-control-icon-font-size);
  font-family: Font Awesome\ 6 Pro, Font Awesome\ 6 Free
}

.carousel-control-next-icon:after {
  content: ""
}