/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
 .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s easse-in-out;
    border-radius: 8px;
    z-index: 1;
  }
  
   .icon-box::before {
    content: "";
    position: absolute;
    background: #cbe0fb;
    right: 0;
    left: 0;
    bottom: 0;
    top: 100%;
    transition: all 0.3s;
    z-index: -1;
  }
  
   /* .icon-box:hover::before {
    background: #52a22a;
    top: 0;
    border-radius: 0px;
  } */
  
   .icon {
    margin-bottom: 15px;
  }
  
   .icon i {
    font-size: 48px;
    line-height: 1;
    color: #52a22a;
    transition: all 0.3s ease-in-out;
  }
  
   .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
   .title a {
    color: #111;
  }
  
   .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
   /* .icon-box:hover .title a,
   .icon-box:hover .description {
    box-shadow: #52a22a;
  }
  
   .icon-box:hover .icon i {
    box-shadow: #52a22a;
  } */
  