/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
  
  a {
    color: #52a22a;
    text-decoration: none;
  }
  
  a:hover {
    color: #3b8af2;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Roboto", sans-serif;
  }

  /*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f6f9fe;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
    background: #e7f1fd;
    color: #52a22a;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
  }
  
  .section-title h3 {
    margin: 15px 0 0 0;
    font-size: 32px;
    font-weight: 700;
  }
  
  .section-title h3 span {
    color: #52a22a;
  }
  
  .section-title p {
    margin: 15px auto 0 auto;
    font-weight: 600;
  }
  
  @media (min-width: 1024px) {
    .section-title p {
      width: 50%;
    }
  }