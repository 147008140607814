/*--------------------------------------------------------------
# Why Us Section
--------------------------------------------------------------*/
.why-us .why-box {
    padding: 30px;
    background: #52a22a;
    color: #fff;
  }
  
  .why-us .why-box h3 {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 30px;
  }
  
  .why-us .why-box p {
    margin-bottom: 30px;
  }
  
  .why-us .why-box .more-btn {
    display: inline-block;
    background: rgba(255, 255, 255, 0.3);
    padding: 6px 30px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: all ease-in-out 0.4s;
  }
  
  .why-us .why-box .more-btn i {
    font-size: 14px;
  }
  
  .why-us .why-box .more-btn:hover {
    color: var(--color-primary);
    background: #fff;
  }
  
  .why-us .icon-box {
    text-align: center;
    background: #fff;
    padding: 40px 30px;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(55, 55, 63, 0.1);
    transition: 0.3s;
  }
  
  .why-us .icon-box i {
    color: var(--color-primary);
    margin-bottom: 30px;
    font-size: 32px;
    margin-bottom: 30px;
    background: rgb(82, 162, 42,0.5);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
  }
  
  .why-us .icon-box h4 {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 30px 0;
    font-family: var(--font-secondary);
  }
  
  .why-us .icon-box p {
    font-size: 15px;
    color: #6c757d;
  }
  
  @media (min-width: 1200px) {
    .why-us .icon-box:hover {
      transform: scale(1.1);
    }
  }
  